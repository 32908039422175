import { Component, OnInit } from '@angular/core';
import Chart from 'chart.js/auto';
import { Router } from '@angular/router';
import { DataService } from './data.service';
import { NotificationService } from '../../services/notificationService';



@Component({
  selector: 'app-project-management',
  templateUrl: './project-management.component.html',
  styleUrl: './project-management.component.scss'
})
export class ProjectManagementComponent implements OnInit {
  chart: any;
  projectList:any[]=[];
  defaultPagination: string | undefined = undefined;
  page: number = 1;
  _itemsPerPage: any = "25";
  isChecked: boolean | undefined = false;
  checkedIdList: any[] = [];
  id: string = "";
  selectedId: string[] = [];
  statusData: string[] = [];
  
  constructor(
    private router:Router,
    private data:DataService,
    private toastr:NotificationService
    
  ) { }

  ngOnInit(): void {
    //this.createChart();
    //this.dataSource.paginator = this.paginator;
    this.getList();
  }
  createChart(){
    this.chart = new Chart("MyChart", {
      type: 'bar', //this denotes tha type of chart

      data: {// values on X-Axis
        labels: ['2022-05-10', '2022-05-11', '2022-05-12','2022-05-13',
								 '2022-05-14', '2022-05-15', '2022-05-16','2022-05-17', ], 
	       datasets: [
          {
            label: "Sales",
            data: ['467','576', '572', '79', '92',
								 '574', '573', '576'],
            backgroundColor: 'blue'
          },
          {
            label: "Profit",
            data: ['542', '542', '536', '327', '17',
									 '0.00', '538', '541'],
            backgroundColor: 'limegreen'
          }  
        ]
      },
      options: {
        aspectRatio:2.5
      }
      
    });
  }
  getList() {
    
   this.data.get().subscribe((val:any)=>{
    if (val) {
      this.projectList = Object.keys(val).map(key => ({ id: key, ...val[key] }));
      console.log(this.projectList)
    }
    })
  }

  refreshPage(){
    this.getList()
  }

  ongoing(){}
  completed(){}
  upcoming(){}
  addproject(){
    this.router.navigate(['/dashboard/project/add']);
  }
  onView(_id:string) {
    this.router.navigate(["/dogManagement/view"], {
      queryParams: { id: _id}
    });
  }

  onDelete(_id:string) {
   this.data.deleteById(_id).subscribe(()=>{
    this.toastr.showError("Deleted","Successfully!!")
    this.refreshPage()
   })
  }

  onEdit(_id:string) {
    this.router.navigate(['/dashboard/project/edit'], {
      queryParams: { id: _id }

    });
  }

  getSelectedId(isCheckedStatus: boolean, id: string) {
    if (isCheckedStatus === true) {
      this.id = id;
      this.isChecked = isCheckedStatus;
      this.checkedIdList.push(this.id);
    } else {
      this.isChecked = undefined;
      this.id = "";
      const index = this.checkedIdList.indexOf(this.id);
      this.checkedIdList.splice(index);
    }
  }
}
