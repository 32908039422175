<div class="container-fluid">
    <!-- <mat-card class="projcard col-lg-12 col-sm-12 col-md-12">
        <mat-card-content>
            <mat-card-title>Project Details </mat-card-title>
            <mat-progress-bar mode="determinate" value="10"></mat-progress-bar>
            <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-12 projdetail">
                  <mat-list-item class="details-container">
                  <mat-list class="detail-item">
                    <span>Manager:</span>
                    <span></span>
                  </mat-list>
                  <div class="row">
                    <mat-list  class="detail-item col-lg-3">
                        <span>Phone:</span>
                        <span></span>
                      </mat-list>
                      <mat-list  class="detail-item col-lg-3">
                        <span>Email:</span>
                        <span></span>
                      </mat-list>
                  </div>
                  <div class="row">
                    <mat-list  class="detail-item col-lg-3">
                        <span>StartDate:</span>
                        <span></span>
                      </mat-list>
                      <mat-list  class="detail-item col-lg-3">
                        <span>EndDate:</span>
                        <span></span>
                      </mat-list>
                  </div>
                  
                  <mat-list  class="pending-works-list ">
                    <span>Pending work:</span>
                    <span>
                        <ul></ul>
                    </span>
                  </mat-list>
                  </mat-list-item>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12 projchart">
                    <div class="chart-container">
                        <canvas  id="MyChart" class="chart">{{ chart }}</canvas>
                  </div>
                </div>
            </div>
        </mat-card-content>
    </mat-card> -->
    <h3>Filters</h3>
    <div  class="filters col-lg-12 col-md-12 col-sm-12">
       <button type="button" id="addButton" class="btn btn-primary add-button" (click)="completed()">Completed</button>
        <button type="button" id="addButton" class="btn btn-primary add-button" style="margin-left: 3%;" (click)="ongoing()" >On-going</button>
        <button type="button" id="addButton" class="btn btn-primary add-button"  style="margin-left: 3%;" (click)="upcoming()" >UpComing</button>
      <button id="addButton" class="btn btn-primary add-button" style="margin-left: 3%;" (click)="addproject()">Add +
        
      </button>
          
      </div>
      <div class="scroll">
        <table
    class="custom-table">
    <thead>
      <tr>
        <th>Company</th>
        <th>Project Name</th>
        <th>Total Expenses</th>
        <th>Project Address</th>
        <th>No of Site</th>
        <th>Start Date </th>
        <th>End Date</th>
        <th>Status</th>
        <th>Action</th>
        </tr>
        </thead>
        <tbody >
            <tr
            *ngFor="
            let element of projectList
            | paginate: { itemsPerPage: 25, currentPage: page }
          "
            >
                
              <td>{{element.companyId}}</td>
              <td>{{element.projectName}}</td>
              <td>{{element.totalExpenses}}</td>
              <td>{{element.projectAddress}}</td>
              <td>{{element.noofsite }}</td>
              <td>{{element.startDate}}</td>
              <td>{{element.endDate}}</td>
              <td>{{element.projectStatus}}</td>
              <td>
                <mat-icon (click)="onEdit(element.id)">edit</mat-icon>
                <mat-icon (click)="onDelete(element.id)" >delete</mat-icon>
              </td>
            </tr>
            </tbody>
            </table>
     </div>
    </div>
