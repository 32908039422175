import { Component } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import moment from 'moment';
import { NotificationService } from '../../../services/notificationService';
import { DataService } from '../data.service';

@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrl: './edit.component.scss'
})
export class EditComponent {
  project: any = {};
  states: string[] = ['State 1', 'State 2', 'State 3'];
  projectForm: FormGroup = new FormGroup({});
  submitted: boolean = false;
  projectData: string[] = ['On-going','Started','Completed','Stopped',];
  id:any;

  constructor(
    private data:DataService,
    private formBuilder: FormBuilder,
    private toastr:NotificationService,
    private router:Router,
    private route:ActivatedRoute
  ) {}

  ngOnInit()  {

    if (this.route.snapshot.queryParamMap.get("id")) {
      // @ts-ignore TS2322
      this.id = this.route.snapshot.queryParamMap.get("id");
      this.getById(this.id);
    }


    this.projectForm= this.formBuilder.group({
      companyId: [1],
      projectName: ["", Validators.required],
      totalExpenses: ["", Validators.required],
      projectAddress: ["", Validators.required],
      noofsite: ["", Validators.required],
      startDate: ["", Validators.required],
      endDate: ["", Validators.required],
      projectStatus: ["", Validators.required],
    });
  }

  getById(_id:any){
    this.data.getById(_id).subscribe((res:any)=>{
      console.log(res,"res")
     if(res){
      //console.log(this.categoryData,"concel")
      this.updateView(res)
     }
    })
    
  }
  updateView(data:any){
    console.log(data,"Edit")
    this.projectForm.patchValue({
      companyId: data.companyId,
      projectName: data.projectName,
      totalExpenses:data.totalExpenses,
      projectAddress: data.projectAddress,
      noofsite: data.noofsite,
      startDate: data.startDate,
      endDate: data.endDate,
      projectStatus: data.projectStatus,
    })
  }

  goBack() {
    this.router.navigate(['/dashboard/project']);
  }
  get f() {
    return this.projectForm.controls;
  }

  onSubmit(data: any) {
    if (this.projectForm.invalid) {
      this.toastr.showError("Error", "");
      return;
    }

    data.updateOn=moment().format();
   // data.isActive = true

    this.data.update(data,this.id).subscribe((res:any)=>{
      console.log('Project Created!',res);
      this.toastr.showSuccess("Successfully!!","Project Added")
      this.router.navigate(['/dashboard/project'])
    })
  }
  onCancel(){
    this.projectForm.reset()
  }
}
