import { Component } from '@angular/core';
//import { AngularFireDatabase } from '@angular/fire/compat/database';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
//import { DatabaseService } from '../../../services/data.service';
import { NotificationService } from '../../../services/notificationService';
import { DataService } from '../data.service';
import moment from 'moment';


@Component({
  selector: 'app-add',
  templateUrl: './add.component.html',
  styleUrl: './add.component.scss'
})
export class AddComponent {
  project: any = {};
  states: string[] = ['State 1', 'State 2', 'State 3'];
  projectForm: FormGroup = new FormGroup({});
  submitted: boolean = false;
  projectData: string[] = ['On-going','Started','Completed','Stopped',];

  constructor(
    private data:DataService,
    private formBuilder: FormBuilder,
    private toastr:NotificationService,
    private router:Router
  ) {}

  ngOnInit()  {
    this.projectForm= this.formBuilder.group({
      companyId: [1],
      projectName: ["", Validators.required],
      totalExpenses: ["", Validators.required],
      projectAddress: ["", Validators.required],
      noofsite: ["", Validators.required],
      startDate: ["", Validators.required],
      endDate: ["", Validators.required],
      projectStatus: ["", Validators.required],
    });
  }

 

  goBack() {
    this.router.navigate(['/dashboard/project']);
  }
  get f() {
    return this.projectForm.controls;
  }

  onSubmit(data: any) {
    if (this.projectForm.invalid) {
      this.toastr.showError("Error", "");
      return;
    }

    data.postOn=moment().format();
    data.isActive = true

    this.data.create(data).subscribe((res:any)=>{
      console.log('Project Created!',res);
      this.toastr.showSuccess("Successfully!!","Project Added")
      this.router.navigate(['/dashboard/project'])
    })
  }
  onCancel(){
    this.projectForm.reset()
  }
}
