<div class="form-container">
  <button class="back-button" (click)="goBack()">
    <mat-icon>arrow_back</mat-icon>
</button>
  <h2>New Project</h2>
  <form [formGroup]="projectForm">
    <div class="form-row">
      <div class="form-group col-sm-12 col-md-12 col-lg-12">
        <label for="companyId">Company Id<span class="requried-field">*</span></label>
        <input
          type="text"
          readonly
          placeholder="Enter companyId"
          class="form-control"
          formControlName="companyId"
          [ngClass]="{ 'is-invalid': submitted && f['companyId'].errors }"
        />
        <div *ngIf="submitted && f['companyId'].errors" class="invalid-feedback">
          <div *ngIf="f['companyId'].errors['required']">Company Id is required</div>
        </div>
      </div>
      <div class="form-group col-sm-12 col-md-12 col-lg-4">
        <label for="projectName">Project Name<span class="requried-field">*</span></label>
        <input
          type="text"
          id="projectName"
          placeholder="Enter projectName"
          class="form-control"
          formControlName="projectName"
          [ngClass]="{ 'is-invalid': submitted && f['projectName'].errors }"
        />
        <div *ngIf="submitted && f['projectName'].errors" class="invalid-feedback">
          <div *ngIf="f['projectName'].errors['required']">Project Name is required</div>
        </div>
      </div>
      <div class="form-group col-sm-12 col-md-12 col-lg-4">
        <label for="totalExpenses">Total Expenses<span class="requried-field">*</span></label>
        <input
          type="text"
          id="totalExpenses"
          placeholder="Enter totalExpenses"
          class="form-control"
          formControlName="totalExpenses"
          [ngClass]="{ 'is-invalid': submitted && f['totalExpenses'].errors }"
        />
        <div *ngIf="submitted && f['totalExpenses'].errors" class="invalid-feedback">
          <div *ngIf="f['totalExpenses'].errors['required']">Total Expenses is required</div>
        </div>
      </div>
      <div class="form-group col-sm-12 col-md-12 col-lg-4">
        <label for="projectAddress">Project Address<span class="requried-field">*</span></label>
        <input
          type="text"
          id="projectAddress"
          placeholder="Enter projectAddress"
          class="form-control"
          formControlName="projectAddress"
          [ngClass]="{ 'is-invalid': submitted && f['projectAddress'].errors }"
        />
        <div *ngIf="submitted && f['projectAddress'].errors" class="invalid-feedback">
          <div *ngIf="f['projectAddress'].errors['required']">Project Address is required</div>
        </div>
      </div>
      <div class="form-group col-sm-12 col-md-12 col-lg-4">
        <label for="noofsite">Site<span class="requried-field">*</span></label>
        <input
          type="text"
          id="noofsite"
          placeholder="Enter noofsite"
          class="form-control"
          formControlName="noofsite"
          [ngClass]="{ 'is-invalid': submitted && f['noofsite'].errors }"
        />
        <div *ngIf="submitted && f['noofsite'].errors" class="invalid-feedback">
          <div *ngIf="f['noofsite'].errors['required']">Site is required</div>
        </div>
      </div>
      <div class="form-group col-sm-12 col-md-4">
        <label for="startDate">Start Date<span class="requried-field">*</span></label>
        <input
          type="date"
          id="startDate"
          placeholder="Enter startDate"
          class="form-control"
          formControlName="startDate"
          [ngClass]="{ 'is-invalid': submitted && f['startDate'].errors }"
        />
        <div *ngIf="submitted && f['startDate'].errors" class="invalid-feedback">
          <div *ngIf="f['startDate'].errors['required']">Start Date is required</div>
        </div>
      </div>

      <div class="form-group col-sm-12 col-md-4">
        <label for="endDate">End Date<span class="requried-field">*</span></label>
        <input
          type="date"
          id="endDate"
          placeholder="Enter endDate"
          class="form-control"
          formControlName="endDate"
          [ngClass]="{ 'is-invalid': submitted && f['endDate'].errors }"
        />
        <div *ngIf="submitted && f['endDate'].errors" class="invalid-feedback">
          <div *ngIf="f['endDate'].errors['required']">End Date is required</div>
        </div>
      </div>

      <div class="form-group col-sm-12 col-md-4">
        <label for="projectStatus">Status<span class="requried-field">*</span></label>
        <select
        id="projectStatus"
        name="projectStatus"
        class="form-control"
        formControlName="projectStatus"
        [ngClass]="{ 'is-invalid': submitted && f['projectStatus'].errors }"
      >
      <option value="" selected disabled>--Please select--</option>
        <option
          *ngFor="let data of projectData"
          [value]="data"
          >{{ data}}
        </option>
      </select>
        <div *ngIf="submitted && f['projectStatus'].errors" class="invalid-feedback">
          <div *ngIf="f['projectStatus'].errors['required']">Product Status is required</div>
        </div>
      </div>
    </div>
    <div class="formbutton">
      <button type="submit" class="btn btn-primary" (click)="onSubmit(projectForm.value)">Submit</button>
      <button type="submit" class="btn btn-danger btn2"  (click)="onCancel()">Cancel</button>
    </div>
   
  </form>
</div>
